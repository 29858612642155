<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :title="formTitle"
    :maskClosable="false"
  >
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="是否主卡" prop="isMain">
        <a-radio-group v-model="form.isMain" button-style="solid" :disabled="formType == 2">
          <a-radio-button v-for="(d, index) in isMainOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="所属主卡卡号" prop="mainCardNo" v-if="form.isMain == 1">
        <a-select
          show-search
          placeholder="请输入所属主卡卡号模糊查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleMainCardSearch"
          @change="handleMainCardChange"
          :loading="loading"
          :disabled="formType == 2"
          v-model="form.mainCardNo"
        >
          <a-select-option v-for="d in mainCardArray" :key="d.cardNo">
            {{ d.cardNo }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="卡号" prop="cardNo">
        <a-input v-model="form.cardNo" placeholder="请输入卡号" :disabled="formType == 2" />
      </a-form-model-item>
      <a-form-model-item label="有效标志" prop="status">
        <a-radio-group v-model="form.status" button-style="solid" :readonly="formType == 1">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="可用余额" prop="balanceAvailable">
        <a-input v-model="form.balanceAvailable" placeholder="请输入可用余额" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="总余额" prop="balanceTotal" v-if="form.isMain == 0">
        <a-input v-model="form.balanceTotal" placeholder="请输入总余额" :disabled="true" />
      </a-form-model-item>
      <div v-if="form.isMain == 1">
        <a-form-model-item label="绑定的车辆自编号" prop="vehicleNo">
          <a-select
            show-search
            allowClear
            placeholder="请输入车辆自编号模糊查找"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleVehicleNoSearch"
            @change="handleVehicleNoChange"
            :loading="loading"
            v-model="form.vehicleNo"
          >
            <a-select-option v-for="d in vehicleNoArray" :key="d.vehicleNo" :value="d.vehicleNo">
              {{ d.vehicleNo }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="绑定的车辆车牌号" prop="licensePlateNum">
          <a-select
            show-search
            allowClear
            placeholder="请输入车辆车牌号模糊查找"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleLicensePlateNumSearch"
            @change="handleLicensePlateNumChange"
            :loading="loading"
            v-model="form.licensePlateNum"
          >
            <a-select-option v-for="d in vehicleNoArray" :key="d.licensePlateNum" :value="d.licensePlateNum">
              {{ d.licensePlateNum }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </div>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import debounce from 'lodash/debounce'
import { getOilCard, addOilCard, updateOilCard, mainCardList } from '@/api/iot/oilCard'
import { searchCarNo } from '@/api/iot/vehicleAccount'
export default {
  name: 'CreateForm',
  props: {
    isMainOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {},
  data() {
    this.handleMainCardSearch = debounce(this.handleMainCardSearch, 500)
    this.handleVehicleNoSearch = debounce(this.handleVehicleNoSearch, 500)
    this.handleLicensePlateNumSearch = debounce(this.handleLicensePlateNumSearch, 500)
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        isMain: '0',
        cardNo: null,
        mainCardId: null,
        mainCardNo: null,
        balanceAvailable: null,
        balanceTotal: null,
        vehicleNo: null,
        licensePlateNum: null,
        createTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        isMain: [{ required: true, message: '是否主卡不能为空', trigger: 'blur' }],
        cardNo: [{ required: true, message: '卡号不能为空', trigger: 'blur' }],
        mainCardId: [{ required: true, message: '所属主卡id不能为空', trigger: 'blur' }],
        balanceAvailable: [{ required: true, message: '可用余额不能为空', trigger: 'blur' }],
        balanceTotal: [{ required: true, message: '总余额不能为空', trigger: 'blur' }]
      },
      mainCardArray: [],
      vehicleNoArray: []
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        isMain: '0',
        cardNo: null,
        mainCardId: null,
        mainCardNo: null,
        balanceAvailable: null,
        balanceTotal: null,
        // vehicleNo: null,
        // licensePlateNum: null,
        createTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.form.balanceAvailable = 0
      this.form.balanceTotal = 0
      this.form.status = 1
      this.form.isMain = '1'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getOilCard(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.handleVehicleNoSearch(this.form.vehicleNo, this.handleVehicleNoChange)
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      console.log('form', this.form)
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateOilCard(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addOilCard(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    },

    handleMainCardSearch(value) {
      console.log('handleMainCardSearch', value)
      let queryParam = {
        mainCardNo: value
      }
      if (value && !value.trim()) {
        queryParam = {
          pageNum: 1,
          pageSize: 10
        }
      }
      this.loading = true
      mainCardList(queryParam).then(response => {
        console.log('mainCardList')
        this.mainCardArray = response
        this.loading = false
      })
    },
    handleMainCardChange(value) {
      this.form.mainCardNo = value
    },
    handleVehicleNoSearch(value, fn) {
      console.log('handleVehicleNoSearch', value)
      let queryParam = {
        vehicleNo: value
      }
      if (value && !value.trim()) {
        queryParam = {
          pageNum: 1,
          pageSize: 10
        }
      }
      this.loading = true
      searchCarNo(queryParam).then(response => {
        this.vehicleNoArray = response
        this.loading = false
        this.$forceUpdate()
        fn && fn(value)
      })
    },
    handleLicensePlateNumSearch(value) {
      console.log('handleLicensePlateNumSearch', value)
      let queryParam = {
        licensePlateNum: value
      }
      if (value && !value.trim()) {
        queryParam = {
          pageNum: 1,
          pageSize: 10
        }
      }
      this.loading = true
      searchCarNo(queryParam).then(response => {
        this.vehicleNoArray = response
        this.loading = false
        this.$forceUpdate()
      })
    },
    handleVehicleNoChange(value) {
      const val = value === undefined ? '' : value
      this.form.vehicleNo = val
      if (val === '') {
        this.form.licensePlateNum = ''
      }
      // 设置车牌号
      const tmpArray = this.vehicleNoArray.filter(p => p.vehicleNo === val)
      if (tmpArray.length > 0) {
        this.form.licensePlateNum = tmpArray[0].licensePlateNum
        this.$forceUpdate()
      }
    },
    handleLicensePlateNumChange(value) {
      const val = value === undefined ? '' : value
      this.form.licensePlateNum = val
      if (val === '') {
        this.form.vehicleNo = ''
      }
      // 设置车辆自编号
      const tmpArray = this.vehicleNoArray.filter(p => p.licensePlateNum === val)
      if (tmpArray.length > 0) {
        this.form.vehicleNo = tmpArray[0].vehicleNo
        this.$forceUpdate()
      }
    }
  }
}
</script>
